<template>
  <div>
    <app-table-registers
      ref="tr"
      :getList="getList"
      @btnNew="
        $refs.elProductModel.show();
        $refs.elProductForm.reset();
      "
    >
      <template slot="tl"></template>
      <template slot="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>PRODUCTO</th>
            <th>ESTADO</th>
            <th>ESTADO DE EVOLUCION</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, index) in list.data" :key="index">
            <td>{{ l.id }}</td>
            <td>{{ l.product_name }}</td>
            <td>
              <SelectState :disabled="true" v-model="l.status"></SelectState>
            </td>
            <td>
              <SelectState
                :disabled="true"
                v-model="l.status_target"
              ></SelectState>
            </td>
            <td>
              <app-span-money
                :moneyCode="1"
                :quantity="l.unit_price"
              ></app-span-money>
            </td>
            <td>
              <button
                class="btn btn-light btn-sm"
                @click="showProductForm(l.product_id)"
              >
                <i class="fas fa-box"></i>
              </button>
              <button
                class="btn btn-light btn-sm"
                @click="
                  $refs.dForm.show();
                  $refs.form.loadReg(l);
                "
              >
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </template>
    </app-table-registers>

    <app-modal-basic ref="dForm">
      <Form ref="form" @submitted="$refs.dForm.hide()"></Form>
    </app-modal-basic>

    <app-modal-basic ref="elProductModel">
      <ProductForm
        ref="elProductForm"
        @submitted="
          $refs.elProductModel.hide();
          afterSave($event);
        "
      ></ProductForm>
    </app-modal-basic>
  </div>
</template>
<script>
import { DentalClinicService } from "../../service";
import SelectState from "../menu/SelectState";
import Form from "./Form.vue";
import ProductForm from "src/lt/store-module/products/ServiceForm.vue";
import { StoreService } from "src/lt/store-module";
export default {
  components: {
    ProductForm,
    SelectState,
    Form
  },
  props: {
    idCategory: {}
  },
  data: () => ({
    list: {}
  }),
  methods: {
    async afterSave(product) {
      if (product.is_new) {
        await DentalClinicService.saveProductStatus({
          status: 0,
          status_target: 0,
          product_id: product.id,
          discount_lab_cost: false
        });
      }
      this.$refs.tr.gl();
    },
    async showProductForm(productId) {
      this.$refs.elProductModel.show();
      let result = await StoreService.getProduct(productId);
      this.$refs.elProductForm.setValue(result);
    },
    getList({ page, search }) {
      return new Promise((rsv) => {
        DentalClinicService.getProductStates({
          page,
          search,
          category_id: this.idCategory
        }).then((res) => {
          this.list = res;
          rsv(res);
        });
      });
    }
  }
};
</script>

<style></style>
